<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      sortable
      class="staff-table"
      show-search-filter
      :headers="headers"
      :search-filters="filtersList"
      :locale="locale"
      do-route
      :resource-query="resourceQuery"
      :show-operations="false"
      :operations="operations"
      :resource-limit="500"
      resource="staff"
      custom-create-path="/staff/manager/new"
      custom-edit-path="/staff/manager"
      :buttons="computedButtons"
      :meta-options="{ deleted: false, location: currentLocation }"
      :transform-fetched-data="checkOnboarding"
      @loading-error="handleLoadingError"
      @search-filter-submit="handleSubmit"
    />
  </th-page-wrapper>
</template>

<script>
import typeOf from 'just-typeof'
import qs from 'qs'
import mapValues from 'just-map-values'
import safeGet from 'just-safe-get'
import onboardingMixin from '@/mixins/onboarding'
import { mapGetters } from 'vuex'

export default {
  name: 'StaffAll',
  metaInfo() {
    return {
      title: this.$t('pages.staff.title')
    }
  },
  setup() {
    const { onboardingConfirmed } = onboardingMixin()
    return {
      onboardingConfirmed
    }
  },
  data() {
    return {
      permissions: {
        create: {
          scopes: ['staff:create']
        },
        edit: {
          scopes: ['staff:update']
        },
        delete: {
          scopes: ['staff:delete']
        }
      },
      filtersList: [
        {
          name: 'staff_number',
          type: 'remote-search-select',
          label: this.$t('pages.staff.all.headers.staff'),
          resource: 'staff',
          optionsValue: 'staff_number',
          fetchHandler: 'getAll',
          computeName: (staff) =>
            this.$formatStaff(staff, ['staff_number', 'fullName'], ' - '),
          modifyQuery: (q) => ({
            q,
            fields: ['staff_number', 'lastname', 'firstname'],
            deleted: false
          })
        },
        {
          name: 'staff_groups',
          type: 'select',
          computeName: (group) => `${group.staff_number} - ${group.name}`,
          placeholder: this.$t('common.inputs.placeholders.search'),
          label: this.$t('pages.staff.all.headers.staff_groups'),
          options: [],
          filterable: true
        },
        {
          name: 'email',
          type: 'remote-search-select',
          useOld: true,
          label: this.$t('pages.staff.all.headers.email'),
          options: [],
          resource: 'staff',
          filterable: true,
          doInitialFetch: false,
          optionsValue: 'email',
          // placeholder: this.$t('common.inputs.placeholders.email'), cant have @ the new i18n
          computeName: (staff) => staff.email,
          modifyQuery: (q) => ({
            q,
            fields: ['email']
          })
        },
        {
          name: 'phonenumbers',
          type: 'input',
          label: this.$t('pages.staff.all.headers.phonenumbers')
        }
      ],
      headers: [
        {
          field: 'staff_number',
          label: this.$t('pages.staff.all.headers.staff_number'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'lastname',
          label: this.$t('pages.staff.all.headers.lastname'),
          minWidth: 140,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'firstname',
          label: this.$t('pages.staff.all.headers.firstname'),
          minWidth: 140,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'email',
          label: this.$t('pages.staff.all.headers.email'),
          minWidth: 200,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'phonenumbers',
          label: this.$t('pages.staff.all.headers.phonenumbers'),
          minWidth: 140,
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            if (!row || !row.phonenumbers) return '-'

            return (
              row.phonenumbers.mobile ||
              row.phonenumbers.main ||
              row.phonenumbers.home ||
              row.phonenumbers.work ||
              row.phonenumbers.any ||
              '-'
            )
          }
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      currentLocation: 'Config/getCurrentLocation',
      isOnboardingRoute: 'Config/getIsOnboardingRoute'
    }),
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    resourceQuery() {
      return {
        query: {
          deleted: false,
          location: this.currentLocation || undefined
        }
      }
    },
    operations() {
      const permissions =
        typeOf(this.permissions) === 'object' ? this.permissions : {}
      return Object.entries(permissions).reduce(
        (ops, [action = '', { scopes = [] }]) => {
          if (!action || !scopes || !scopes.length) return ops

          const permissionVal = this.$checkPermissions({ scopes })

          if (typeOf(permissionVal === 'boolean')) {
            ops[action] = permissionVal
          }

          return ops
        },
        {}
      )
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    buttons() {
      return [
        {
          type: 'create',
          scopes: ['staff:create']
        }
      ]
    }
  },
  mounted() {
    this.fetchFilterResources()
    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    handleSubmit(filters) {
      const filterQueries = Object.entries(filters).reduce(
        (accFiltersResult, currentFilter) => {
          const [key, valueObj] = currentFilter
          return {
            ...accFiltersResult,
            [key]:
              safeGet(valueObj, 'originalData.queryInput') || valueObj.value
          }
        },
        {}
      )
      this.makeQuery(filterQueries)
    },
    makeQuery(v) {
      const q = { ...this.parsedQuery, ...v }

      const filter = qs.stringify({
        filter: mapValues(q, (value, key) => {
          if (value === null || value === undefined) return undefined
          return value
        })
      })

      const route = `${this.$route.path}?${filter}`
      this.$router.push(route)
    },
    checkOnboarding(data) {
      if (this.isOnboardingRoute && !this.onboardingConfirmed('staff')) {
        const defaultStaff = data.find((staff) => staff.default)
        if (defaultStaff) {
          this.$router.push({
            name: 'staff-edit',
            params: { id: defaultStaff.id }
          })
        }
      }
      if (this.isOnboardingRoute) {
        // Unset onboarding once entered in the page
        this.$store.dispatch('Config/setIsOnboardingRoute', false)
      }
      return data
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.staff.title')
        })
      })
    },
    async fetchFilterResources() {
      try {
        const { staffGroups } = await this.$resourceFetch({
          resource: 'staffGroups',
          query: { query: { deleted: false } }
        })
        this.filtersList = this.filtersList.map((filter) => {
          let options = []
          if (filter.name === 'staff_groups') {
            options = staffGroups.map((group) => ({
              label: group.name,
              value: group.id
            }))
          }
          return { ...filter, options }
        })
      } catch (err) {
        this.handleLoadingError(err)
      }
    },
    parsePhoneNumbers(staff, q) {
      if (typeOf(staff.phonenumbers) === 'string') return staff.phonenumbers
      if (!q) return

      if (typeOf(staff.phonenumbers) === 'object') {
        return Object.values(staff.phonenumbers).find((n) => n.includes(q))
      }
    }
  }
}
</script>

<style scoped>
.popover {
  margin-right: 10px;
}

.staff-table :deep(.filter-container) {
  overflow: visible;
}
</style>
